import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

function App() {
  const [userName, setUserName] = useState("");

  return (
    <BrowserRouter>
      <nav style={styles.nav}>
        <a
          href="https://whop.com/first-stock"
          style={styles.left}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="app.png" alt="App Logo" style={styles.logo} />
          <h1 style={styles.title}>FIRST STOCK</h1>
        </a>
        <div style={styles.right}>{userName || "Loading..."}</div>
      </nav>
      <Routes>
        <Route path="/" element={<Home setUserName={setUserName} />} />
      </Routes>
    </BrowserRouter>
  );
}

const styles = {
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  },
  left: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    textAlign: "left",
    textDecoration: "none",
  },
  title: {
    marginLeft: "10px",
    fontSize: "18px",
  },
  logo: {
    width: "32px",
    height: "32px",
    margin: "0px",
  },
  right: {
    flex: 1,
    textAlign: "right",
  },
};

export default App;

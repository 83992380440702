const DataCard = ({ data }) => {
  const formatWeight = (weight) => {
    const number = parseFloat(weight);
    return number % 1 === 0 ? `${number}€` : `${number.toFixed(2)}€`;
  };

  return (
    <div className="data-card">
      {data.wts && <h5>{formatWeight(data.wts)}</h5>}
      <img
        src={
          data.category === "SNEAKERS"
            ? `https://img.firststock.fr/${data.sku}.png`
            : data.category === "BILLETERIE"
            ? "https://img.firststock.fr/BILLET.png"
            : data.category === "VETEMENTS"
            ? `https://img.firststock.fr/${data.articlevetement}.png`
            : data.category === "OBJETS"
            ? `https://img.firststock.fr/${data.articleobject}.png`
            : "https://img.firststock.fr/OTHER.png"
        }
        alt="Data"
        onError={(e) => {
          e.target.src = "https://img.firststock.fr/OTHER.png";
        }}
      />

      {data.category === "SNEAKERS" && <h4>{data.sku}</h4>}
      {data.category === "VETEMENTS" && <h4>{data.articlevetement}</h4>}
      <h3>{data.title}</h3>
      {(data.category === "SNEAKERS" || data.category === "VETEMENTS") && (
        <p>{data.size}</p>
      )}
      {data.category === "OBJETS" && <p>{data.articleobject}</p>}
      {data.category === "BILLETERIE" && <p>{data.dateevent}</p>}
    </div>
  );
};

export default DataCard;
